import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Button
} from "reactstrap";

class ProductHeader extends React.Component {

    render() {
        return (
          <>
            <Navbar
              className="navbar-horizontal navbar-main navbar-dark "
              expand="lg"
              id="navbar-main"
            >
              <Container>
                <NavbarBrand to="/product/scaniwell" tag={Link}>
                  <img
                    alt="..."
                    src={require("assets/img/brand/scaniwell_icon.png")}
                  />
                  
                </NavbarBrand>
                
                <UncontrolledCollapse
                  className="navbar-custom-collapse"
                  navbar
                  toggler="#navbar-collapse"
                >
                  <div className="navbar-collapse-header">
                    <Row>
                      <Col className="collapse-brand" xs="6">
                        
                      </Col>
                      <Col className="collapse-close" xs="6">
                        <button
                          aria-controls="navbar-collapse"
                          aria-expanded={false}
                          aria-label="Toggle navigation"
                          className="navbar-toggler"
                          data-target="#navbar-collapse"
                          data-toggle="collapse"
                          id="navbar-collapse"
                          type="button"
                        >
                          <span />
                          <span />
                        </button>
                      </Col>
                    </Row>
                  </div>
                  
                  <hr className="d-lg-none" />
                  <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem className="d-none d-lg-block ml-lg-4">
                  <span className="text-black">COVID 19 - Cercanía segura</span>
                  </NavItem>
                    <NavItem className="d-none d-lg-block ml-lg-4">
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        href="http://www.groupware.com.co/product/scaniwell"
                        target="_blank"
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-rocket mr-2" />
                        </span>
                        <span className="nav-link-inner--text">Más información</span>
                      </Button>
                    </NavItem>
                  </Nav>
                </UncontrolledCollapse>
              </Container>
            </Navbar>
          </>
        );
      }
}

export default ProductHeader;
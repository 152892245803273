/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col, Badge } from "reactstrap";

class Login extends React.Component {
  render() {
    return (
      <>
        <footer className="py-5" id="footer-main">
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              <Col xl="6">
                <div className="copyright text-center text-xl-left text-muted">
                <h4 className="mb-0">
                  © {new Date().getFullYear()}{" "}
                  
                    Groupware Technology
                    </h4>
                </div>
              </Col>
              <Col xl="6">
                <Nav className="nav-footer justify-content-center justify-content-xl-end">
                  <NavItem>
                    <div className="d-flex align-items-center" style={{marginRight: 16}}>
                          
                            <h4 className="mb-0">
                            <i className="fas fa-map-marker-alt" /> Retiro - Antioquia
                            </h4>
                          
                        </div>
                  </NavItem>
                  <NavItem>
                    <div className="d-flex align-items-center"  style={{marginRight: 16}}>
                          
                            <h4 className="mb-0">
                            <i className="fab fa-whatsapp" /> 3006543177
                            </h4>
                          
                        </div>
                  </NavItem>
                  <NavItem>
                  <div className="d-flex align-items-center">
                          
                         
                            <h4 className="mb-0">
                            <i className="far fa-envelope-open" /> info@groupware.com.co
                            </h4>
                          
                        </div>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Login;

import React from "react";
// react library for routing
import { Route, Switch, Redirect } from "react-router-dom";

import routes from "../routes/product.js";

class ProductLayout extends React.Component {

    getRoutes = routes => {
        return routes.map((prop, key) => {
          if (prop.collapse) {
            return this.getRoutes(prop.views);
          }
          if (prop.layout === "/product") {
            return (
              <Route
                path={prop.layout + prop.path}
                component={prop.component}
                key={key}
              />
            );
          } else {
            return null;
          }
        });
      };

    render() {
        return (
            <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/product" />
          </Switch>
        );
    }

}

export default ProductLayout;

import Scaniwell from "../views/products/scaniwell/Index.js";

const productRoutes = [
    {
      path: "/scaniwell",
      name: "Scaniwell",
      component: Scaniwell,
      layout: "/product"
    }
  ];
  
  export default productRoutes;
  
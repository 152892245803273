/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import ProductHeader from "components/Headers/ProductHeader.js";

class Index extends React.Component {
  render() {
    return (
      <>
        {/*<IndexNavbar />*/}
        {/*<ProductHeader />*/}
        <div className="main-content">
          <IndexHeader />
          <section className="py-6 pb-9 bg-default">
            <Container fluid>
              <Row className="justify-content-center text-center">
                <Col md="6">
                  <h2 className="display-3 text-white">
                    Tecnologías de la 4Revolución industrial
                  </h2>
                  <p className="lead text-white">
                    En Groupware Technology usamos tecnologías de la 4Revolución industrial para crear soluciones que mejoren la vida de las personas, el hogar y las empresas. 
                    Nos gusta innovar combinando las más modernas herramientas de software y hardware para dar utilidad a nuestras creaciones.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-lg-0 mt--7">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle mb-4">
                            <i className="fas fa-robot" />
                          </div>
                          <h4 className="h3 text-info text-uppercase">
                            Inteligencia Artificial (AI)
                          </h4>
                          <p className="description mt-3">
                            Imagina un mundo donde las cámaras de vigilancia alertaran a la policía de un delito antes de que éste suceda...
                          </p>
                          <div>
                            <Badge color="info" pill>
                            Machine learnig
                            </Badge>
                            <Badge color="info" pill>
                            Visión computacional
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle mb-4">
                            <i className="fas fa-microchip" />
                          </div>
                          <h4 className="h3 text-success text-uppercase">
                            Internet de las Cosas (IoT)
                          </h4>
                          <p className="description mt-3">
                           Y en el que tu refrigerador pueda avisarte que la leche se acabó mientras llegas del trabajo.
                          </p>
                          <div>
                            <Badge color="success" pill>
                              Hardware
                            </Badge>
                            <Badge color="success" pill>
                              Firmware
                            </Badge>
                            <Badge color="success" pill>
                              Comunicaciones
                            </Badge>
  
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle mb-4">
                            <i className="fas fa-chart-line" />
                          </div>
                          <h4 className="h3 text-warning text-uppercase">
                            Ciencia de datos
                          </h4>
                          <p className="description mt-3">
                            Entonces, podrás tomar mejores deciciones basadas en la gran cantidad de información que tienes disponible.
                          </p>
                          <div>
                            <Badge color="warning" pill>
                              Reportes
                            </Badge>
                            <Badge color="warning" pill>
                              Indicadores
                            </Badge>
                            <Badge color="warning" pill>
                            Predicción
                            </Badge>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-1_2.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>Aplicaciones móviles</h1>
                    <p>
                      Desde la palma de tu mano controla lo que imagines. Groupware Technology desarrolla modernas aplicaciones móviles para clientes comunes con necesidades digitales.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fab fa-android" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Android
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fab fa-apple" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              iOS
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fab fa-react" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              React native
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-2.png")}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h1>Desarrollo de Consolas Web</h1>
                    <p>
                      Gestiona toda tu operación a través de un Dashboard Web. Groupware Technology construye agradables e intuitivas aplicaciones Web para el manejo de tus dispositivos, captura de información, visualización de indicadores y reportes en tiempo real. 
                    </p>

                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="warning"
                            >
                              <i className="fab fa-html5" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Html 5
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="warning"
                            >
                              <i className="fab fa-react" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              React JS
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="warning"
                            >
                              <i className="fab fa-angular" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Angular
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="warning"
                            >
                              <i className="fab fa-java" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              API Spring boot
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>


          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-3_2.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>Desarrollo de Hardware</h1>
                    <p>
                      ¡No limites tu imaginación! En Groupware conectamos y controlamos dispositivos y sensores para automatizar procesos. Con el Internet de las cosas comunicamos los objetos cotidianos con las personas.
                    </p>
                    <label
                      className="font-weight-bold text-info mt-5">
                      Sistema embebidos
                    </label>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/landing-4.png")}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h1>Inteligencia artificial</h1>
                    <p>
                      Ahora podemos enseñar a las máquinas a realizar labores más especializadas. Con los datos del entorno, entrenamos y afinamos sistemas para optimizar los procesos.
                    </p>
                    <label
                      className="font-weight-bold text-warning mt-5">
                      Machine learning
                    </label>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          

          <section className="py-7 bg-white">
            <Container>
              <Row className="row-grid justify-content-center">
                <Col className="text-center" lg="8">
                  
                  <div className="text-center">
                    <h4 className="display-4 mb-5 mt-5">
                      Herramientas y plataformas
                    </h4>

                    <Row className="justify-content-center">

                    <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://developer.android.com/studio"
                          id="tooltip616015001"
                          target="_blank"
                        >
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/android.png")}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip616015001">
                          Android Studio - Entorno de desarrollo integrado para la plataforma Android
                        </UncontrolledTooltip>
                      </Col>

                      <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://developer.apple.com/xcode/"
                          id="tooltip82987604"
                          target="_blank"
                        >
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/xcode.jpg")}
                          />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip82987604">
                          Xcode - Entorno de desarrollo integrado para la plataforma iOS
                        </UncontrolledTooltip>
                      </Col>

                      <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://es.reactjs.org/"
                          id="tooltip374813715"
                          target="_blank">
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/react.jpg")}/>
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip374813715">
                          React - Librería JavaScript para construir interfaces de usuarios
                        </UncontrolledTooltip>
                      </Col>

                      <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://angular.io/"
                          id="tooltip211254026"
                          target="_blank"
                        >
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/angular.jpg")}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip211254026">
                          Angular - Librería JavaScript para construir interfaces de usuarios
                        </UncontrolledTooltip>
                      </Col>

                      <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://spring.io/projects/spring-boot"
                          id="tooltip374813717"
                          target="_blank">
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/spring.png")}/>
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip374813717"
                        >
                          SpringBoot - Framework para el desarrollo de microservicios con Java
                        </UncontrolledTooltip>
                      </Col>

                      <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://www.python.org/"
                          id="tooltip731835410"
                          target="_blank"
                        >
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/python.jpg")}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip731835410"
                        >
                          Python - Lenguaje de programación
                        </UncontrolledTooltip>
                      </Col>

                      <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://www.tensorflow.org/"
                          id="tooltip170669606"
                          target="_blank"
                        >
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/tensor.jpg")}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip170669606"
                        >
                          TensorFlow - Librería de código abierto para aprendizaje automático
                        </UncontrolledTooltip>
                      </Col>

                      <Col className="my-2" md="2" xs="3">
                        <a
                          href="https://keras.io/"
                          id="tooltip374813716"
                          target="_blank">
                          <img
                            alt="..."
                            className="img-fluid rounded-circle"
                            src={require("assets/img/icons/keras.jpg")}
                          />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target="tooltip374813716"
                        >
                          Keras - Librería de redes neuronales de código abierto escrita en Python
                        </UncontrolledTooltip>
                      </Col>
                      
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        <AuthFooter />
      </>
    );
  }
}

export default Index;

import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import axios from 'axios';

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  CardHeader,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  ListGroupItem,
  ListGroup,
  Modal
} from "reactstrap";

import Background from '../../../assets/img/scaniwell_banner.jpg';
import AuthFooter from "../../../components/Footers/AuthFooter.js";
import Icon from '../../../assets/img/icons/products/scaniwell_icon.png';
import Icon1 from '../../../assets/img/scaniwell_app.png';
import Icon2 from '../../../assets/img/scaniwell_wris.png';

class Scaniwell extends React.Component {

    state = {
      select: null,
      tagsinput: ["Bucharest", "Cluj", "Iasi", "Timisoara", "Piatra Neamt"],
      slider1Value: "100.00",
      slider2Values: ["200.00", "400.00"],
      reactQuillText: "",
      answer: {
        name: "",
        mail: "",
        phone: "",
        company: "",
        message: ""
      },
      infoModal: false,
      waitModal: false,
    };

    emailIsValid = email => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }
  
    handleInput = event => {
      console.log('-- ' + [event.target.value]);
      var { answer } = this.state;
      answer[[event.target.name]] =  event.target.value;
      
      this.setState({
        answer: answer,
      });
    };

    toggleModal = state => {
      this.setState({
        [state]: !this.state[state]
      });
    };

    send = () => {
      const { answer } = this.state;
  
      if (answer.name === "") {
        this.setState({
          infoModalText: "Por favor dinos tu nombre",
        });
        this.toggleModal("infoModal");
        return;
      }

      if (answer.mail.length == 0 || !this.emailIsValid(answer.mail)) {
        this.setState({
          infoModalText: "Por favor ingrese un correo válido",
        });
        this.toggleModal("infoModal");
        return;
      }
    
      if (answer.phone === "") {
        this.setState({
          infoModalText: "Queremos comunicarnos contigo, regálanos un número de contacto",
        });
        this.toggleModal("infoModal");
        return;
      }
  
      if (answer.company === "") {
        this.setState({
          infoModalText: "Dinos de que empresa eres",
        });
        this.toggleModal("infoModal");
        return;
      }
  
      if (answer.message === "") {
        this.setState({
          infoModalText: "Un mensaje por favor",
        });
        this.toggleModal("infoModal");
        return;
      }
  
  
      this.toggleModal("waitModal");
      const url = "http://groupwarecontactservices-env.eba-cf4sbqca.us-west-2.elasticbeanstalk.com/groupware-contact-services/message";
      axios.post(url, answer)
      .then(res => {
        const response = res.data;
        console.log("--OK response " + JSON.stringify(response));
  
        this.setState({
          infoModalText: "Que bien!! tu información ha sido enviada",
        });
        this.toggleModal("infoModal");
  
        this.setState({answer: {
            name: "",
            mail: "",
            phone: "",
            company: "",
            message: ""
          }
        });
  
        this.timeOutFunction = setTimeout(
            function() {
              window.location.reload();
            }.bind(this),
            4600
        );
  
        
        
      }).catch(error => {
        console.log("--Error: " + error);
        this.setState({
          infoModalText: "Ups!! se presentó un problema, intenta nuevamente por favor!",
        });
        this.toggleModal("infoModal");
      }).then(() => {
        // always executed
        console.log("--End request: ");
        this.toggleModal("waitModal");
      });
  
  
    }

    render() {
        return (
          <>
            <div className="main-content">

              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.infoModal}
                toggle={() => this.toggleModal("infoModal")}>

                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Información
                  </h5>
                  <button
                    aria-label="Close"
                    className="close"
                    data-dismiss="modal"
                    type="button"
                    onClick={() => this.toggleModal("infoModal")}
                  >
                    <span aria-hidden={true}>×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.state.infoModalText}
                </div>
                <div className="modal-footer">
                  
                  <Button color="primary" data-dismiss="modal" type="button" onClick={() => this.toggleModal("infoModal")}>
                    Aceptar
                  </Button>
                </div>

                </Modal>

                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.waitModal}
                  toggle={() => this.toggleModal("waitModal")}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Información
                    </h5>
                  
                  </div>
                  <div className="modal-body">
                    Enviando información...
                  </div>
              </Modal>

              <div style={{backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}>
                <section className="bg-default" style={{opacity: 0.8, paddingTop: 60}}>
                  <Container>
                    <Row className="row-grid align-items-center">
                      <Col className="order-md-1" md="6">
                      <div className="pr-md-5">
                      <h1 className="display-1 text-white font-weight-bold mb-0">
                      <img
                                  alt="..."
                                  className="img-fluid"
                                  width={320}
                                  src={Icon}
                                /> 
                        </h1>
                        <p className="display-4 text-white font-weight-light">
                         Cercanía segura
                        </p>
                        </div>
                        
                        
                        
                          
                       
                      </Col>

                      <Col className="order-md-2" md="6">
                        <h1 className="display-2 text-white font-weight-bold mb-0" >
                          
                          </h1>
                        </Col>

                        
                    </Row>
                  </Container>
                </section>
                <section className="py-6 bg-default" style={{opacity: 0.8}}>
                <Container>
                    <Row className="row-grid align-items-center">
                      <Col className="order-md-1" md="6">
                      <Button
                            className="btn-neutral my-2"
                            color="default"
                            to="/admin/dashboard">
                            Contáctanos
                          </Button>
                      </Col>
                      </Row>  
                      </Container>
                </section>
              </div>
              {/*
              <section className="section section-lg pt-lg-0 mt--7">
                <Container>
                  <Row className="justify-content-center">
                    <Col lg="12">
                      <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            
                            <h4 className="h3 text-info text-uppercase">
                              Algunos de tus datos
                            </h4>
                            <p className="description mt-3">
                              Déjanos conocer algunos datos sobre tí
                            </p>
                            </CardBody>

                      </Card>                    
                    </Col>
                  </Row>
                </Container>
              </section>
              */}
            </div>

            <section className="py-6 bg-white">
            <Container>
              <Row className="justify-content-center text-center">
                <Col className="text-center" lg="8">
                  
                    <h2 className="display-3">
                        Acerca del producto
                        
                      </h2>
                    <p className="lead">
                     Scaniwell es una solución tecnológica que te ayudará con el cumplimiento de los protocolos de bioseguridad referente al control de aforo en recintos, y la cercanía segura con otras personas.

                    </p>
                 
                </Col>
              </Row>
              </Container>
            </section>

            <section className="py-4 bg-white">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row>
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                        <div className="text-center">
                        <img
                            alt="..."
                            className="img-fluid"
                            width={320}
                            src={Icon1}
                          /> 
                        </div>
                        
                         
                        <div className="text-center">
                            <p className="mt-4 ">
                            Alerta al supervisor con un SMS o mensaje de WhatsApp cuando se supera la capacidad de aforo de un recinto.
                          </p>
                            </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                        <div className="text-center">
                          <img
                              alt="..."
                              className="img-fluid"
                              width={320}
                              src={Icon2}
                            /> 
                            </div>
                            <div className="text-center">
                            <p className="mt-4 ">
                            Alerta al usuario con una vibración y una luz LED cuando no se respeta la "distancia social" con otros brazaletes.
                          </p>
                            </div>
                          
                          
                          
                        </CardBody>
                      </Card>
                    </Col>
                    
                    
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          {/*-- */}


          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/product-landing-1.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <h1>Dashboard de indicadores</h1>
                    <p>
                      Scaniwell brinda una consola dashboard en donde podrás ver en tiempo real los estados de las zonas que se están escanenado para el control de los protocolos de aforo y cercanía segura.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fas fa-layer-group" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Zonas con sobrecupo
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fas fa-th-large" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Semáforo
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fas fa-exclamation" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Alertas de cercanía
                            </h4>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-6">
            <Container>
              <Row className="row-grid align-items-center">
                <Col md="6">
                  <img
                    alt="..."
                    className="img-fluid"
                    src={require("assets/img/theme/product-landing-2.png")}
                  />
                </Col>
                <Col md="6">
                  <div className="pr-md-5">
                    <h1>Control de zonas</h1>
                    <p>
                      Scaniwell permite gestionar todas las zonas que deban ser controladas, definir la capacidad de personas permitidas, los dispositivos que realizan el escaneo, entre otras opciones. 
                    </p>

                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="warning"
                            >
                              <i className="fas fa-mobile" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Dispositivos
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="warning"
                            >
                              <i className="fas fa-male" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Control de capacidad
                            </h4>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="warning"
                            >
                              <i className="far fa-comment" />
                            </Badge>
                          </div>
                          <div>
                            <h4 className="mb-0">
                              Mensajes SMS/WhatsApp
                            </h4>
                          </div>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-6 bg-white">
            <Container>
              <Row className="row-grid align-items-center">
                
              <Col lg="12">
              <div className="card-wrapper">
              <Card>
                  <CardHeader>
                    <h3 className="mb-0">Contáctanos</h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: this.state.yourName
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-user" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                id="name-input"
                                placeholder="Nombre"
                                type="text"
                                name="name"
                                onChange={event => this.handleInput(event)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="6">
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: this.state.emailAddress
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-envelope" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                id="mail-input"
                                placeholder="Correo electrónico"
                                type="email"
                                name="mail"
                                onChange={event => this.handleInput(event)}
                              />
                            </InputGroup>
                          </FormGroup>
                        </Col>
                      </Row>

                      

                      <Row>
                      <Col md="6">
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: this.state.phoneNumber
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-phone" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                id="phone-input"
                                placeholder="Teléfono de contacto"
                                type="text"
                                name="phone"
                                onChange={event => this.handleInput(event)}
                              />
                              
                            </InputGroup>
                          </FormGroup>
                        </Col>

                        <Col md="6">
                          <FormGroup>
                            <InputGroup
                              className={classnames("input-group-merge", {
                                focused: this.state.phoneNumber
                              })}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="fas fa-globe-americas" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                id="company-input"
                                placeholder="Empresa"
                                type="text"
                                name="company"
                                onChange={event => this.handleInput(event)}
                              />
                              
                            </InputGroup>
                          </FormGroup>
                        </Col>
                        <Col md="12">
                        <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="exampleFormControlTextarea1"
                        >
                          Mensaje
                        </label>
                        <Input
                          id="message-input"
                          rows="3"
                          type="textarea"
                          name="message"
                          onChange={event => this.handleInput(event)}
                        />
                      </FormGroup>
                      </Col>
                      </Row>
                    </Form>
                    <Button
                        className="my-2"
                        color="success"
                        onClick={this.send}>
                          <span className="btn-inner--icon">
                          <i className="fas fa-paper-plane mr-2" />
                        </span>
                        Enviar
                      </Button>
       
                  </CardBody>
                </Card>
              </div>
              </Col>
              </Row>
            </Container>
          </section>

          <AuthFooter />
          </>
        );
    }

}

export default Scaniwell;
/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react library for routing
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";

//import Background from '../../assets/img/video_00.gif';
import Background from '../../assets/img/banner_04.jpg';
//import Background from '../../assets/img/video_03.gif';
import Icon from '../../assets/img/ic_launcher_3.png';

class IndexHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header bg-default pt-5 pb-7" style={{backgroundImage: `url(${Background})`, backgroundRepeat: "no-repeat", backgroundPosition: "center", backgroundSize: "cover"}}>
       
        
          <Container >
            <div className="header-body">
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="pr-5" >
                    <h1 className="display-2 text-white font-weight-bold mb-0">
                    <img
                            alt="..."
                            className="img-fluid"
                            width={320}
                            src={Icon}
                          /> 
                    </h1>
                    <h2 className="display-4 text-white font-weight-light">
                      Haciendo simple e intuitivo lo cotidiano
                    </h2>
                    
                  </div>
                </Col>
                <Col lg="6">
                  <Row className="pt-5">
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                            <i className="ni ni-favourite-28" />
                          </div>
                          <h5 className="h3">Pasión</h5>
                          <p>
                            Ponemos el corazón en lo que hacemos.
                          </p>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                            <i className="ni ni-bulb-61" />
                          </div>
                          <h5 className="h3">Innovación</h5>
                          <p>
                          Construimos nuevas soluciones tecnológicas.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="pt-lg-5 pt-4" md="6">
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                            <i className="ni ni-spaceship" />
                          </div>
                          <h5 className="h3">Exploración</h5>
                          <p>
                            Buscamos más allá de lo evidente.
                          </p>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                            <i className="ni ni-settings" />
                          </div>
                          <h5 className="h3">Creación</h5>
                          <p>
                          Ideamos diferentes alternativas.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
         
        </div>
      </>
    );
  }
}

export default IndexHeader;
